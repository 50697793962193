import axios from "axios";

import $ from "jquery";
import "bootstrap";
import Popper from "popper.js";

import lozad from "lozad";
import bsCustomFileInput from "bs-custom-file-input";
import { computeDistance } from "../shared/helpers/location";
window.computeDistance = computeDistance;

global.$ = global.jQuery = $;

$(function () {
  $('[data-toggle="tooltip"]').tooltip();
});

$(document).ready(function () {
  bsCustomFileInput.init();

  const observer = lozad();
  observer.observe();
});

class InteractionLogger {
  logOrganizationInteraction(organizationId, type) {
    axios.post(
      `/search/api/organization/${organizationId}/interaction/${type}`
    );
  }
}

window.InteractionLogger = new InteractionLogger();

// import.meta.glob(["../images", "../images/**", "../img/**"]);
